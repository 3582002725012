import React from "react";
import classNames from "classnames";
import { Container, Image } from "@atoms";

const ImageBlock = ({ image, paddingBottom, className: _className }) => {
  return (
    <section
      className={classNames(
        {
          "pb-28": paddingBottom,
        },
        _className
      )}
    >
      <Container>
        <Image image={image} aspectRatio={[2, 1]} />
      </Container>
    </section>
  );
};

ImageBlock.defaultProps = {};

export default ImageBlock;
